<template>
  <div class="dialogLoader">
    <v-dialog class="dialogLoader" :value="value" persistent width="300">
      <v-card :color="ownerColors.ownerPrimaryColor" dark class="pt-2 pl-2">
        <v-card-text :style="`color: ${ownerColors.ownerOnPrimaryColor}`">
          {{ $t("common.waitMoment") }}
          <v-progress-linear
            indeterminate
            :color="ownerColors.ownerOnPrimaryColor"
            height="6px"
            class="mt-3"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "dialogLoader",
  props: {
    value: { required: true, type: Boolean, default: false },
  },
  computed: {
    ...mapGetters({
      ownerColors: "auth/getOwnerColors",
    }),
  },
};
</script>
